const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6924029', space_js: '//gflpjby.gnpad.cn/common/kfe/source/n/static/u-blu-n.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6924032', space_js: '//gflpjby.gnpad.cn/source/ez/static/y/common/hov-f-ro.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6924031', space_js: '//gflpjby.gnpad.cn/source/dy/xgn_ue/qh.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6924033 ', space_js: '//gflpjby.gnpad.cn/production/fazip/w_g/ss.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6924034', space_js: '//gflpjby.gnpad.cn/production/g_b/common/aj/qxh_tx.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    GuideNative: NATIVE_1,
    GuideBanner: BANNER,
    HomeInter: INTER,
    HomeBanner: BANNER,
    HomeNative: NATIVE_1,
    HomeNativeCenter: NATIVE_2,
    HomeNativeBottom: NATIVE_3,
    HotInter: INTER,
    HotBanner: BANNER,
    HotNative: NATIVE_1,
    DetailInter: INTER,
    DetailBanner: BANNER,
    DetailNative: NATIVE_1,
};
